import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { DeleteAlert } from '../../actions/AlertActions';
import './alert.css';

export default function Alert() {
  const dispatch = useDispatch();
  // const { alerts } = useSelector((state) => ({
  //   alerts: state.alerts,
  // }));

  const handleClick = () => {
    // dispatch(DeleteAlert(alert));
  };

  // if (!alerts || alerts.length <= 0) {
  //   return null;
  // }
  return (
    <div className="alertpage">
      {/* {alerts.map((alert) => (
        <>
          <div
            key={alert.id}
            className={alert.alertType !== 'danger-stay' ? `alertpage-alert-${alert.alertType}` : 'alertpage-alertnone'}
          >
            <div
              className={`alertpage-alert-alertspan-span-danger`}
              style={{ position: 'absolute', top: '5px', right: '10px', cursor: 'pointer' }}
              aria-hidden
              onClick={handleClick}
            />
            <div className={`alertpage-alert-alertspan-${alert.alertType}`} />
            <span className="alertpage-alert-msg">
              {alert.msg ? alert.msg : 'Something went wrong try after sometime.'}
            </span>
          </div>
        </>
      ))} */}
    </div>
  );
}
