import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// material
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Box, Stack, AppBar, Toolbar, IconButton, Chip, Typography } from '@mui/material';
import axiosInstance from '../../api/withJWTServer';
import { dashboardStateAction } from '../../Redux-Store/actions/dashboardActions';
// components
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';
import Server from '../../api';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  background: '#FFFFFF',
  
  [theme.breakpoints.up('lg')]: {
    // height: '61px'
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const dispatch = useDispatch();
  const [dashboardCount, setDashboardCount] = useState({});

  // const { dashboardState } = useSelector((state) => ({
  //   dashboardState: state.dashboard.dashboardState?.data?.data[0],
  // }));

  // const { role } = useSelector((state) => ({
  //   role: state.authentication.user?.data?.data[0].role,
  // }));

  const role = localStorage.getItem('role');
  console.log('roleondashboed', role);

  useEffect(() => {
    // dispatch(dashboardStateAction());
  }, []);

  // useEffect(() => {
  //   setDashboardCount(dashboardState);
  // }, [dashboardState]);

  const useStyles = makeStyles((theme) => ({
    sideBar: {
      '@media print': {
        display: 'none',
      },
    },
  }));

  let alertComponent;

  if (role === 'Reviewer') {
    alertComponent = (
      <Chip
        label={`Total Registered Vendors: ${dashboardCount?.total_vendors}`}
        style={{
          background: '#d1e7d9',
          color: '#035421',
          fontSize: '17px',
          marginRight: '15px',
          border: '1.5px solid #0B8238',
          borderRadius: '4px',
          fontWeight: '600',
        }}
      />
    );
  }
  useEffect(() => {
    dispatch(dashboardStateAction());
  }, []);

  const classes = useStyles();

  const fetchData = () => {
    try {
      Server.get('admin/get-profile', {
        headers: {
          token: localStorage.getItem('token'),
        },
      }).then((res) => {
        console.log('resprofile', res);
        setProfileDetail(res?.data?.data[0]);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [profileDetail, setProfileDetail] = useState({});
  console.log('profileDetail', profileDetail);

  useEffect(() => {
    // fetchData();
  }, []);

  const cropName = (name) => {
    const all = name;
    const words = all?.split(' ');

    const truncatedText = words?.slice(0, 1).join(' ');

    // Add ellipsis if there are more than three words
    const displayText = words?.length > 1 ? `${truncatedText} ` : truncatedText;
    console.log('displayText', displayText);
    return displayText;
  };
  return (
    <RootStyle>
      <ToolbarStyle className={`${classes.sideBar}`}>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box>
          {/* <Typography style={{ color: '#060606', display: 'block', fontSize: '17px', fontWeight: '600' }}>
            Welcome{' '}
          </Typography> */}
          {/* <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title={profileDetail?.firstname}>
              <Typography sx={{ alignItems: 'flex-start', color: 'black', fontWeight: 'bold', fontSize: '24px' }}>
                {cropName(`${profileDetail?.firstname} ${profileDetail?.lastname}`)}
              </Typography>
            </Tooltip>
            <Chip
              label={`${role?.toUpperCase()}`}
              style={{
                background: '#d1e7d9',
                color: '#035421',
                fontSize: '12px',
                marginLeft: '15px',
                border: '1.5px solid #0B8238',
                borderRadius: '4px',
                fontWeight: '600',
              }}
            />
          </Box> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {alertComponent}

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
