import axios from 'axios';

console.log(process.env.REACT_APP_ENV);
  
export default axios.create({
  baseURL: process.env.REACT_APP_ENV === 'STAGE' ? process.env.REACT_APP_BASE_URL_STAGE : process.env.REACT_APP_BASE_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
    'Content-Language': 'browser',
  },
});
