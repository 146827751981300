import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/withJWTServer';
import { actionTypes } from '../actionTypes';
// import { axiosClient } from "../axiosClient";
import Server from '../../api/index';

// -------------------Get Users Action------------------------
export const getUsersList = createAsyncThunk(actionTypes.GET_USERS, async (params, thunkAPI) => {
  console.log('params in userlidt', params);
  try {
    console.log('params in userlidt', params);
    const url = params?.filterData
      ? `admin/get-consumers?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `admin/get-consumers?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    console.log('response Users', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

//   DDWNLOAD excel-------------
export const downloadUserExcel = createAsyncThunk(actionTypes.DOWNLOAD_EXCEL, async (params, thunkAPI) => {
  console.log('params in dwnload ecxwl', params);
  try {
    axiosInstance
      .get('admin/get-user-report', {
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `UserList.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    // return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
    // dispatch(HandleExceptionWithSecureCatch(err));
  }
});
