export const actionTypes = {
  //  --------------Auth---------------
  ON_LOGIN: 'ON_LOGIN',
  ON_LOGOUT: 'ON_LOGOUT',
  RESET_STATE: 'RESET_STATE',

  //  --------------Dashboard---------------
  GET_DASHBOARD_STATE: 'GET_DASHBOARD_STATE',
  GET_USERS: 'GET_USERS',
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',

  //  --------------Vendor---------------
  GET_VENDORS: 'GET_VENDORS',
  DELETE_VENDOR: 'DELETE_VENDOR',
  UPDATE_VENDOR: 'DELETE_VENDOR',

  //  --------------Categories---------------
  GET_CATEGORIES: 'GET_CATEGORIES',
  ADD_CATEGORIES: 'ADD_CATEGORIES',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  DELETE_CATEGORIES: 'DELETE_CATEGORIES',
  GET_CATEGORIES_IMAGE: 'GET_CATEGORIES_IMAGE',
  GET_CATEGORIES_DDL: 'GET_CATEGORIES_DDL',

  //  --------------SubCategories---------------
  GET_SUB_CATEGORIES: 'GET_SUBCATEGORIES',
  ADD_SUB_CATEGORIES: 'ADD_SUBCATEGORIES',
  UPDATE_SUB_CATEGORIES: 'UPDATE_SUBCATEGORIES',
  DELETE_SUB_CATEGORIES: 'DELETE_SUBCATEGORIES',
  DOWNLOAD_SUB_CATEGORY_IMAGE: 'DOWNLOAD_SUB_CATEGORY_IMAGE',

  //  --------------AgeGroups---------------
  GET_AGE_GROUPS: 'GET_AGE_GROUPS',
  ADD_AGE_GROUPS: 'ADD_AGE_GROUPS',
  UPDATE_AGE_GROUPS: 'UPDATE_AGE_GROUPS',
  DELETE_AGE_GROUPS: 'DELETE_AGE_GROUPS',
  DOWNLOAD_AGE_GROUP_IMAGE:'DOWNLOAD_AGE_GROUP_IMAGE',
  //  --------------Reviewers---------------
  GET_CONTENTS: 'GET_CONTENTS',
  ADD_CONTENTS: 'ADD_CONTENTS',
  UPDATE_CONTENTS: 'UPDATE_CONTENTS',
  DELETE_CONTENTS: 'DELETE_CONTENTS',
  DOWNLOAD_CONTENT_DOCS: 'DOWNLOAD_CONTENT_DOCS',
  DELETE_CONTENT_DOCS: 'DELETE_CONTENT_DOCS',

  //  --------------Subscription---------------
  GET_SINGLE_SUBSCRIPTION: 'GET_SINGLE_SUBSCRIPTION',
  GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
  ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTIONS',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',

  // ---------------Recruitment---------------------
  // Vendor jobs
  GET_SINGLE_VENDOR_JOB: 'GET_SINGLE_VENDOR_JOB',
  GET_VENDOR_JOBS: 'GET_VENDOR_JOBS',
  ADD_VENDOR_JOB: 'ADD_VENDOR_JOB',
  UPDATE_VENDOR_JOB: 'UPDATE_VENDOR_JOB',
  DELETE_VENDOR_JOB: 'DELETE_VENDOR_JOB',

  //  Candidates
  GET_SINGLE_CANDIDATE: 'GET_SINGLE_CANDIDATE',
  GET_CANDIDATES: 'GET_CANDIDATES',
  ADD_CANDIDATE: 'ADD_CANDIDATE',
  UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
  DELETE_CANDIDATE: 'DELETE_CANDIDATE',

  // mster /job categories
  GET_SINGLE_JOB_CATEGORY: 'GET_SINGLE_JOB_CATEGORY',
  GET_JOB_CATEGORIES: 'GET_JOB_CATEGORIES',
  ADD_JOB_CATEGORY: 'ADD_JOB_CATEGORY',
  UPDATE_JOB_CATEGORY: 'UPDATE_JOB_CATEGORY',
  DELETE_JOB_CATEGORY: 'DELETE_JOB_CATEGORY',

  // mster /language
  GET_SINGLE_LANGUAGE: 'GET_SINGLE_LANGUAGE',
  GET_LANGUAGES: 'GET_LANGUAGES',
  ADD_LANGUAGE: 'ADD_LANGUAGE',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  DELETE_LANGUAGE: 'DELETE_LANGUAGE',

  // mster /skill
  GET_SINGLE_SKILL: 'GET_SINGLE_SKILL',
  GET_SKILLS: 'GET_SKILLS',
  ADD_SKILL: 'ADD_SKILL',
  UPDATE_SKILL: 'UPDATE_SKILL',
  DELETE_SKILL: 'DELETE_SKILL',

  // mster /eduction
  GET_SINGLE_EDUCATION: 'GET_SINGLE_EDUCATION',
  GET_EDUCATIONS: 'GET_EDUCATIONS',
  ADD_EDUCATION: 'ADD_EDUCATION',
  UPDATE_EDUCATION: 'UPDATE_EDUCATION',
  DELETE_EDUCATION: 'DELETE_EDUCATION',

  // mster /sallary type
  // sallaray
  GET_SINGLE_SALARY_TYPE: 'GET_SINGLE_SALARY_TYPE',
  GET_SALARY_TYPES: 'GET_SALARY_TYPES',
  ADD_SALARY_TYPE: 'ADD_SALLAREAY_TYPE',
  UPDATE_SALARY_TYPE: 'UPDATE_SALARY_TYPE',
  DELETE_SALARY_TYPE: 'DELETE_SALARY_TYPE',
};
