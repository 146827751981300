import React, { useState } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';


// material
import { Stack, TextField, Card, Container, Typography,Box,Grid,CssBaseline } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled,createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from "../../Styles/Login";
import topImg from '../../Assets/brainlight.png';
import AlertLong from '../../components/comman/AlertLong';
import Page from '../../components/Page';
import Companylogo from "../../Assets/Images/Companylogo.png";
import apis from '../apis/apis';
import { HandleExceptionWithSecureCatch } from '../../Redux-Store/actions/CombineCatch';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------
const theme = createTheme();
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    position: 'relative',
    background: '#165f91',
  },

  height: '100vh',
}));
const SideStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '40%',
    position: 'fixed',
    top: '10%',
    backgroundImage: `url('')`,
    backgroundSize: 'cover', // Set the background size to cover
  },

  height: '100vh',
}));
const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  height: '95vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 'auto'),
}));

const SubSection = styled(Card)(({ theme }) => ({
  width: 340,
  maxWidth: 350,
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0, 'auto'),
  boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
  padding: '50px',
}));

const ChipSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 20,
  right: 20,
}));
export default function ValidateOTP() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [alert, setShowAlert] = useState(false);
  const [alertResponse, setAlertResponse] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required').matches(/@/, 'Email must contain the "@" symbol')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
    .matches(/(\.com|\.in)$/, 'Email must end with ".com" or ".in"'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      otp:'',
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      setSubmitLoader(true);
      apis
        .validateotp({
          email: value.email,
          otp: value.otp
        })
        .then((response) => {
          
          localStorage.setItem('userName', value.email);
          localStorage.setItem('activeCode', value.otp);
          // setShowAlert(true);
          // setAlertResponse(response);
          // setSubmitLoader(false);
          navigate('/resetpassword');
        })
        .catch((err) => {
          // if (!navigator.onLine) {
          //   dispatch(HandleExceptionWithSecureCatch(undefined));
          //   setSubmitLoader(false)
          // }else{
          dispatch(HandleExceptionWithSecureCatch(err));
          setSubmitLoader(false);
          // }
        });
    },
  });

  const handleForgetPage = () => {
    // props.handleForget();
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="">
      <ThemeProvider theme={theme}>
      <Box  sx={{
          width: "70%",
          mx: "auto",
          border: "none",
          boxShadow: "none!important",
        }}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "40vh",
                width: "100%",
                left: "30px",
              }}
            >
              {/* <img src={Orangebits} alt="Obsimg" /> */}
              <img src={Companylogo} alt="logo" style={{ width: "45%" }} />
              <h1 className="ms-3">
                One Place By <br />
                Spinoff
              </h1>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box>
            <>
              <Grid>
              <Box {...styles.parentBox}>
                <Typography
                  component="h1"
                  variant="h5"
                  {...styles.parentHeading}
                >
                  VARIFY OTP
                </Typography>
             <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="User Name"
                    color="warning"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    autoComplete="otp"
                    type="password"
                    label="OTP"
                    color="warning"
                    {...getFieldProps('otp')}
                    error={Boolean(touched.otp && errors.otp)}
                    helperText={touched.otp && errors.otp}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
                {/* <button onClick={handleForgetPage}>Login</button> */}
                <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "25px",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="warning"
                      loading={submitLoader && true}
                      style={{
                        // textTransform: "inherit",
                        fontFamily: "Outfit",
                        borderRadius: "20px",

                        fontWeight: "bold",
                        height: "40px",
                      }}
                      {...styles.parentButton}
                      // className={classes.submit}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
              </Form>
            </FormikProvider>
                  </Box>
                 
                </Grid>
        </>
            </Box>
          </Grid>
        </Grid>
      </Box>
      </ThemeProvider>
    </Page>
   
  );
}
