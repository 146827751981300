import React, { useState, useEffect } from 'react';
import { Card, Stack, Grid, TextField, Container, Box } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { HandleExceptionWithSecureCatch } from '../Redux-Store/actions/CombineCatch';
import Page from '../components/Page';
import axiosInstance from '../api/withJWTServer';

const Profile = () => {
  const { role } = useSelector((state) => ({
    role: state.auth.loggedUser?.role,
  }));
  const [profileDetail, setProfileDetail] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const fetchData = () => {
    try {
      // axiosInstance.get('admin/get-profile').then((res) => {
      //   setProfileDetail(res.data.data[0]);
      //   formik.setValues(res.data.data[0]);
      // });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const LoginSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name  is required'),
  });
  const formik = useFormik({
    initialValues: {
      firstname: profileDetail.firstname || '',
      lastname: profileDetail.lastname || '',
      email: profileDetail.email || '',
      mobile_number: profileDetail.mobile_number || '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { resetForm }) => {
      setSubmitLoader(true);
      const params = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        mobile_number: values.mobile_number,
      };
      try {
        if (
          profileDetail?.firstname !== values.firstname ||
          profileDetail?.lastname !== values.lastname ||
          profileDetail?.email !== values.email ||
          profileDetail?.mobile_number !== values.mobile_number
        ) {
          axiosInstance
            .patch('admin/update-profile', params)
            .then((response) => {
              setSubmitLoader(false);
              dispatch(HandleExceptionWithSecureCatch(response));
              fetchData();
            })
            .catch((err) => {
              dispatch(HandleExceptionWithSecureCatch(err));
              setSubmitLoader(false);
            });
        }
      } catch (error) {
        setSubmitLoader(false);
      }
    },
  });
  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const hasFieldsChanged = () => {
    return (
      formik.values.firstname !== profileDetail.firstname ||
      formik.values.lastname !== profileDetail.lastname ||
      formik.values.email !== profileDetail.email ||
      formik.values.mobile_number !== profileDetail.mobile_number
    );
  };
  return (
    <Page title="User">
      <Container>
        <br />
        <Card sx={{ p: 3 }}>
          <Stack spacing={1}>
            <h3>Profile</h3>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  fullWidth
                  id="firstname"
                  name="firstname"
                  autoComplete="firstname"
                  label="First Name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  fullWidth
                  id="lastname"
                  name="lastname"
                  autoComplete="lastname"
                  label="Last Name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  disabled={role === 'Reviewer' || role === 'Admin' ? 1 : 0}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  fullWidth
                  id="mobile_number"
                  name="mobile_number"
                  autoComplete="mobile_number"
                  label="Phone Number"
                  value={formik.values.mobile_number}
                  InputProps={{
                    inputProps: {
                      maxLength: 10,
                    },
                  }}
                  onChange={formik.handleChange}
                  type="text"
                  disabled={role === 'Admin' ? 1 : 0}
                />
              </Grid>
              <Grid item lg={12} md={6} xs={12}>
                <Box sx={{ display: 'flex', float: 'right' }}>
                  <LoadingButton
                    onClick={handleSubmit}
                    loading={submitLoader && true}
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ float: 'right' }}
                    disabled={!hasFieldsChanged()}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
};

export default Profile;
