import { useEffect } from 'react';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';

import './App.css';

export default function App() {
  useEffect(() => {
    
  }, []);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
