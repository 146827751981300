import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { IconButton, Box, Drawer } from '@mui/material';
import { Margin } from '@mui/icons-material';
import { makeStyles } from '@material-ui/styles';
import Companylogo from '../../Assets/Images/Companylogo.png';
import { styles } from '../../Styles/Components/header';
// mock
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
// import { Margin } from '@mui/icons-material';

// import loginIcon from '../../Assets/LoginIcon.png';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const ImageStyle = styled('div')(({ theme }) => ({
  width: '80%',
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  useEffect(() => {
    isOpenSidebar = false;
  }, []);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const useStyles = makeStyles((theme) => ({
    sideBar: {
      '@media print': {
        /* Add your print-specific styles here */
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <NavSection navConfig={navConfig} sx={{ Margin: '10' }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, background: 'white' },
          }}
        >
          <DrawerHeader {...styles.drawerHeader}>
            <IconButton onClick={onCloseSidebar}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '  0',
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <img src={Companylogo} alt="obs img" style={{ width: '100%' }} />
                </Box>
              </Box>
            </IconButton>
            <IconButton>
              {/* {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )} */}
            </IconButton>
          </DrawerHeader>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          className={`${classes.sideBar}`}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: 'white',
            },
          }}
        >
          <DrawerHeader {...styles.drawerHeader}>
            {/* <IconButton onClick={handleDrawerClose} > */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '  0',
                cursor: 'pointer',
              }}
            >
              <Box>
                <img src={Companylogo} alt="obs img" style={{ width: '100%' }} />
              </Box>
            </Box>
            {/* </IconButton> */}
            <IconButton>
              {/* {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )} */}
            </IconButton>
          </DrawerHeader>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
