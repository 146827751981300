// @mui
import { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, CssBaseline, container, Typography } from '@mui/material';
import Companylogo from '../Assets/Images/Companylogo.png';
import { styles } from '../Styles/Login';
// hooks
import useResponsive from '../hooks/useResponsive';

// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------
const theme = createTheme();
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    position: 'relative',
    background: '#165f91',
  },
  height: '100vh',
}));
const SideStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '40%',
    position: 'fixed',
    top: '10%',
    backgroundImage: `url('')`,
    backgroundSize: 'cover', // Set the background size to cover
  },

  height: '100vh',
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  position: 'relative',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  useEffect(() => {
    
    const isAuth = JSON.parse(localStorage.getItem('user_state'));
    if (!isAuth?.auth?.isLogged || isAuth === null) {
      navigate('/login');
    } else {
      navigate('/dashboard/app');
    }
  }, []);
  return (
    <Page title="Login">
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: '70%',
            mx: 'auto',
            border: 'none',
            boxShadow: 'none!important',
          }}
        >
          <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={7} lg={7} sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '40vh',
                  width: '100%',
                  left: '30px',
                }}
              >
                <img src={Companylogo} alt="logo" style={{ width: '45%' }} />
                <h2 className="ms-3">
                  One Place By <br />
                  Spinoff
                </h2>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box>
                <LoginForm />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </Page>
  );
}
