import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link,useNavigate } from 'react-router-dom';

// material
import { Stack, TextField, Card, Container, Typography,Grid,Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { HandleExceptionWithSecureCatch } from '../../../Redux-Store/actions/CombineCatch';
import apis from '../../apis/apis';
import { styles } from "../../../Styles/Login";
import AlertLong from '../../../components/comman/AlertLong';
// ----------------------------------------------------------------------

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  height: '95vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 'auto'),
}));

const SubSection = styled(Card)(({ theme }) => ({
  width: 340,
  maxWidth: 350,
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0, 'auto'),
  boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
  padding: '50px',
}));

const ChipSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 20,
  right: 20,
}));
export default function ForgetPassword(props) {
  const [alert, setShowAlert] = useState(false);
  const [alertResponse, setAlertResponse] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
     email: Yup.string().email('Email must be a valid email address').required('Email is required') .matches(/@/, 'Email must contain the "@" symbol')
     .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
     .matches(/(\.com|\.in)$/, 'Email must end with ".com" or ".in"'),
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      setSubmitLoader(true);
      apis
        .forgotPassword({
          email: value.email,
        })
        .then((response) => {
          
          setShowAlert(true);
          setAlertResponse(response);
          setSubmitLoader(false);
          navigate('/validateotp');
        })
        .catch((err) => {
          // if (!navigator.onLine) {
          //   dispatch(HandleExceptionWithSecureCatch(undefined));
          //   setSubmitLoader(false)
          // }else{
          dispatch(HandleExceptionWithSecureCatch(err));
          setSubmitLoader(false);
          // }
        });
    },
  });

  const handleForgetPage = () => {
    props.handleForget();
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
    <Grid>
    <Box {...styles.parentBox}>
    <Typography
        component="h1"
        variant="h5"
        {...styles.parentHeading}
      >
        FORGET PASSWORD
      </Typography>
      <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={1}>
                
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="username"
                    type="email"
                    color="warning"
                    label="User Name"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                 
                  <Link
                     component={Link}
                     variant="subtitle2"
                     to="#"
                     underline="hover"
                     onClick={() => handleForgetPage()}
                     style={{ textDecoration: 'none', margin: '0 auto', fontSize: '15px', cursor: 'pointer' }}
                   >
                     Login
                   </Link>
                </Stack>
                {/* <button onClick={handleForgetPage}>Login</button> */}
                <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "25px",
                    }}
                  >
                <LoadingButton
                type="submit"
                fullWidth
                disableElevation
                variant="contained"
                color="warning"
                loading={submitLoader && true}
                style={{
                // textTransform: "inherit",
                fontFamily: "Outfit",
                borderRadius: "20px",
                fontWeight: "bold",
                   height: "40px",
                 }}
                 {...styles.parentButton}
                 // className={classes.submit}
                >
                Submit
                </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
    </Box>
    </Grid>
     
    </>
  );
}
