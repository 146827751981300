import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

// Get Category
export const getCategories = createAsyncThunk(actionTypes.GET_CATEGORIES, async (params, thunkAPI) => {

  const mainUrl = `/category/all/paginate?&top=${params?.paginationModel?.pageSize}&skip=${params?.paginationModel.page+1}`;
  console.log('paramssss',params);
  const url = params?.filterData
     ? `${mainUrl}&query=${params?.filterData}`
     : `${mainUrl}`;
     
  console.log("Paginationurl",url);
 
  try {
    
    const response = await axiosInstance.get(url);
    console.log('response of categories', response);

  
    return response.data;
    // return response.data.slice(params?.page + 1, 
    //   params?.rowsPerPage);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Add Category
export const addCategories = createAsyncThunk(
  actionTypes.ADD_CATEGORIES,

  async (params, thunkAPI) => {
  
    // console.log('paramshghgf', params);
    try {
      const response = await axiosInstance.post(`/category/create`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response of add Category', response);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Update categories
export const updateCategory = createAsyncThunk(actionTypes.UPDATE_CITY, async (params, thunkAPI) => {
  console.log('params in update', params);
  try {
    const response = await axiosInstance.put(`/category/update/${params.id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response Category update', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Delete Category
export const deleteCategories = createAsyncThunk(actionTypes.DELETE_CATEGORIES, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.delete(`/category/delete/${params.id}`);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get  Category Image
export const getCategoriesImage = createAsyncThunk(actionTypes.GET_CATEGORIES_IMAGE, async (params, thunkAPI) => {
  try {
  
    const imgUrl =`${axiosInstance.getUri()}category/download/${params}`
    return imgUrl;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get  Category for DDL
export const getCategoriesDdl = createAsyncThunk(actionTypes.GET_CATEGORIES_DDL, async (params, thunkAPI) => {
  try {
  
    const response  = await axiosInstance.get(`/Category/all/dropdown`);
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});