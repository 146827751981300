import { createSlice } from '@reduxjs/toolkit';
import { addAgeGroups, deleteAgeGroup, getAgeGroups, updateAgeGroup } from '../../actions/ageGroupActions';
import { actionTypes } from '../../actionTypes';

// redux state
const initialState = {
  category: undefined,
  error: '',
  rowcount:0,
  isLoading: false,
};

// slice or reducer
const AgeGroup = createSlice({
  name: 'AgeGroup',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseCategory(builder, addAgeGroups);
    caseCategory(builder, updateAgeGroup);
    caseCategory(builder, getAgeGroups);
    caseCategory(builder, deleteAgeGroup);
  },
});
export default AgeGroup.reducer;

const caseCategory = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.ADD_AGE_GROUPS)) {
      state.ageGroups = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_AGE_GROUPS)) {
      state.isLoading = false;
      state.ageGroups = action.payload;
     
    } else if (action.type.includes(actionTypes.GET_AGE_GROUPS)) {
      state.isLoading = false;
      state.ageGroups = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_AGE_GROUPS)) {
      state.ageGroups = action.payload;
      state.isLoading = false;
    }
  });
};
