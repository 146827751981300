import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { deleteCategories, getCategories } from '../../Redux-Store/actions/categoryActions';

// Import necessary components and functions
import SwitchBtn from '../../Component/DataGridComponents/SwitchBtn';
import StatusColumn from '../../Component/DataGridComponents/StatusColumn';
import CommonActiveDeactiveBox from '../../Component/ModelBox/CommonActiveDeactiveBox';
import CommonDeleteBox from '../../Component/ModelBox/CommonDeleteBox';
import PagesTopBar from '../../Component/PagesTopBar';
import Page from '../../components/Page';
import ActionColumn from '../../Component/TableComponent/ActionColumn';
import DataGridHeader from '../../Component/DataGridComponents/DataGridHeader';
import ShowMessage from '../../Component/ShowMessage';
import CategoryActionMenu from '../../sections/@dashboard/category/CategoryActionMenu';
// -------------------------------------------------------------
const theme = createTheme();
function CustomToolbar(props) {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (e) => {
    console.log('column search', e.target.value);
    // Update the state if you want instant updates
    setSearch(e.target.value);
  };

  const onKeyDown = (e) => {
    // console.log("dwn");
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
  };
  const handleColumnSelection = (e, value) => {
    console.log('selection ', value);
  };
  return (
    <DataGridHeader
      showSearch
      showBulkAction={false}
      showDropdown={false}
      showExport
      defaultPropsCols=""
      onAutocompleteChange=""
      quickFilterProps=""
      handleClick={handleClick}
      handleCloseMenu={handleCloseMenu}
      openMenu={openMenu}
      anchorEl={anchorEl}
      onchange={onChange}
      onkeydown={onKeyDown}
      searchValue={search}
      handleColumnSelection={handleColumnSelection}
    />
  );
}

const Category = () => {
  // Define state variables inside the functional component
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [open, setOpen] = useState(false);
  // const [isloading, setIsLoading] = useState(false);
  const [categoryDelete, setcategoryDelete] = useState();
  const [filterData, setFilterData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');

  // const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });

  const { category, isloading } = useSelector((state) => ({
    category: state?.category?.category,
    isloading: state.category?.isLoading,
  }));

  const paramsData = {
    filterData,
    paginationModel,
  };
  useEffect(() => {
    dispatch(getCategories(paramsData));
  }, [paginationModel]);

  // Define event handlers or utility functions here
  const handleSubmit = () => {
    dispatch(deleteCategories({ id: categoryDelete }))
      .unwrap()
      .then((response) => {
        console.log('response in delete category', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage('Record deleted Successfully!!');
          dispatch(getCategories(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };
  const handleDeleteCancel = () => {
    // setReviewerDelete(e.categorId);
    setOpen(!open);
  };

  const handleDelete = (categoryId) => {
    setcategoryDelete(categoryId);
    setOpen(!open);
  };
  const handleEdit = (rowData) => {
    navigate('/dashboard/create_category', {
      state: {
        categoryData: rowData,
      },
    });
  };

  const handleSearch = (e) => {
    console.log('search', e.target.value);
    setFilterData(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleResetFilter = (e) => {
    setFilterData(null);
    dispatch(getCategories(paramsData));
  };

  const handleAction = (categorId, action, isactive, rowData) => {
    if (action === 'Edit') {
      handleEdit(rowData);
    } else if (action === 'Delete') {
      handleDelete(categorId);
    } else {
      // handleActivate(categorId, action, isactive, rejectReason);
    }
  };

  const getPaginationModelValue = (model) => {
    // Handle pagination model change
    setPaginationModel(model);
  };

  const handleFilter = (e) => {
    dispatch(getCategories(paramsData));
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object', object);
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    // {
    //   field: `category_Image`,
    //   headerName: 'category Image',
    //   width: 150,
    //   type: 'binary',
    //   renderCell: (params) => {
    //     console.log('imagePrams', params);
    //     return (
    //       <img
    //         src={`${params.row?.icon_file_path ? params.row?.icon_file_path : '---'}`}
    //         height="40px"
    //         width="40px"
    //         style={{ margin: '10px', borderRadius: '5px' }}
    //         alt="img"
    //       />
    //     );
    //   },
    // },
    { field: `title`, headerName: 'Title', width: 250, type: 'string' },
    { field: `description`, headerName: 'Description', width: 250, type: 'string' },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <CategoryActionMenu
          actionTaken={handleAction}
          categorId={params?.row?.id}
          isactive={params.row?.isactive}
          categoryDetail={params.row}
        />
      ),
    },
  ];

  const rows = [];
  return (
    <Page title="User" sx={{ width: '100%' }}>
      <ThemeProvider theme={theme}>
        {/* Render CommonActiveDeactiveBox if openActiveDeactive state is true */}
        {openActiveDeactive ? (
          <CommonActiveDeactiveBox
            description="Are you sure you want to "
            isOpen={openActiveDeactive}
            handleCancel={handleActiveDeactiveClick}
          />
        ) : null}
        {/* Render CommonDeleteBox if open state is true */}
        {open ? (
          <CommonDeleteBox
            openDialog={open}
            handleDeleteYes={handleSubmit}
            handleClose={handleDeleteCancel}
            resetFilter={handleResetFilter}
            title="Category"
            description="Are you sure you want to delete Category ?"
          />
        ) : null}

        <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
          {/* Render PagesTopBar */}
          <PagesTopBar
            Add
            title="Categories"
            subTitle="List of all Categories in your app."
            btnText="Add"
            link="/dashboard/create_category"
            handleSearch={handleSearch}
            handleFilter={handleFilter}
            resetFilter={handleResetFilter}
            searchValue={searchValue}
          />
          {/* Render Table */}
          <Box sx={{ marginTop: '20px', width: '100%' }}>
            <Grid container>
              <Grid item lg={12} xs={12} sm={12}>
                <DataGrid
                  style={{
                    borderRadius: '10px',
                    fontSize: '14px',
                    minHeight: '300px',
                    overflow: 'auto',
                  }}
                  paginationModel={paginationModel}
                  onPaginationModelChange={getPaginationModelValue}
                  pageSizeOptions={[5, 10, 25]}
                  paginationMode="server"
                  loading={isloading}
                  rows={category?.data !== undefined ? category?.data : []}
                  columns={columns}
                  getRowId={(row) => row.id}
                  rowCount={category?.totalItems}
                  slots={
                    {
                      // toolbar: CustomToolbar,
                    }
                  }
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: {
                          bgcolor: '#ffffff',
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
        <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
      </ThemeProvider>
    </Page>
  );
};

export default Category;
