import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

// Get Category
export const getSubCategories = createAsyncThunk(actionTypes.GET_SUB_CATEGORIES, async (params, thunkAPI) => {
 
  const mainUrl = `/subCategory/all?&top=${params?.paginationModel?.pageSize}&skip=${params?.paginationModel.page+1}`;
 
  let url = params?.filterData
     ? `${mainUrl}&query=${params?.filterData}`
     : `${mainUrl}`;

     if(params?.categoryId){
      url += `&categoryId=${params?.categoryId}`;
     }
  
  try {
  
    const response = await axiosInstance.get(`${url}`);
    return response.data;

  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// get subcategory ddl
export const getSubCategoriesDdl = createAsyncThunk(actionTypes.GET_SUB_CATEGORIES, async (params, thunkAPI) => {

    const url = `/subCategory/category/${params}`;
 
   try {
     const response = await axiosInstance.get(`${url}`);
   
     return response.data;
   } catch (e) {
     return thunkAPI.rejectWithValue(e);
   }
 });

// Add Category
export const addSubCategories = createAsyncThunk(
  actionTypes.ADD_SUB_CATEGORIES,

  async (params, thunkAPI) => {
    console.log('paramshghgf', params);
    try {
      const response = await axiosInstance.post(`/subCategory/create`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response of add Category', response);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Update categories
export const updateSubCategory = createAsyncThunk(actionTypes.UPDATE_SUB_CATEGORIES, async (params,id,thunkAPI) => {
 
  console.log("paramsubcategory",params);
  
  try {
    const response = await axiosInstance.put(`/subCategory/update/${params.id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response Category update', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Delete Category
export const deleteSubCategories = createAsyncThunk(actionTypes.DELETE_SUB_CATEGORIES, async (params, thunkAPI) => {
 
  try {

    const response = await axiosInstance.delete(`/SubCategory/delete/${params.id}`);
  
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get Sub Category Image
export const getSubCategoriesImage = createAsyncThunk(actionTypes.DOWNLOAD_SUB_CATEGORY_IMAGE, async (params, thunkAPI) => {
  try {
    console.log("paramid")
   // const response = await axiosInstance.get(`/category/image/${params}`);
    const imgUrl = await `${axiosInstance.getUri()}subCategory/download/${params}`;
    return imgUrl;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
