import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';

import Server from '../../api';
// import { HandleExceptionWithSecureCatch } from './CombineCatch';

export const loginAdmin = createAsyncThunk(actionTypes.ON_LOGIN, async (params, thunkAPI) => {
  try {
    const response = await Server.post('auth/login', params);
  
    console.log('response of login', response);
    // HandleExceptionWithSecureCatch(response);

    if (response.data) {
      localStorage.setItem('token', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
    }

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const logoutAdmin = createAsyncThunk(actionTypes.ON_LOGOUT, async () => {
  try {
    localStorage.clear();
    console.log('logout sucessfully');
  } catch (e) {
    console.log('logout failed');
  }
});
