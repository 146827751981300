import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  InputAdornment,
  CssBaseline,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Label from '../../components/Label';
import { addCategories, updateCategory, getCategoriesImage } from '../../Redux-Store/actions/categoryActions';
import ShowMessage from '../../Component/ShowMessage';

import Iconify from '../../components/Iconify';

const theme = createTheme();
const CreateCategory = () => {
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryFormData = useLocation();

  const categoryData = categoryFormData?.state?.categoryData;

  console.log('categoryData', categoryData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [previewURL, setPreviewUrl] = useState(null);
  const [fileError, setFileError] = useState('');
  const [logoImage, setLogoImage] = useState('');
  const [selectedFileName, setFileName] = useState('');
  const categorySchema = Yup.object().shape({
    Title: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special charactors not allowed')
      .required('Title is required')
      .min(2, 'Title should not less than 2 characters')
      .max(30, 'Title should not  more than 30 characters'),

    Description: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special charactors not allowed')
      // .required('Description is required')
      // .min(2, 'Description should not less than 2 characters')
      .max(30, 'Description should not more than 30 characters'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: categoryData ? categoryData?.title : '',
      Description: categoryData ? categoryData?.description : '',
      Background_Color: categoryData ? categoryData?.backgroundColor : '',
      remember: true,
    },
    validationSchema: categorySchema,

    onSubmit: (values) => {
      console.log('value', values);
      setSubmitLoader(true);

      const paramsData = categoryData
        ? {
            id: JSON.parse(categoryData.id),
            backgroundColor: values.Background_Color,
            title: values.Title,
            description: values.Description,
            file: logoImage,
          }
        : {
            backgroundColor: values.Background_Color,
            title: values.Title,
            description: values.Description,
            file: logoImage,
          };
      console.log('paramsData', paramsData);
      if (categoryData?.id) {
        dispatch(updateCategory(paramsData))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update Category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);
              setErrorMessage('Category updated Successfully!!');
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);

              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addCategories(paramsData))
          .unwrap()
          .then((response) => {
            console.log('response of add category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);

              setErrorMessage('Category created Successfully!!');
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);

              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });

  useEffect(() => {
    if (categoryData) {
      dispatch(getCategoriesImage(categoryData?.id))
        .unwrap()
        .then((response) => {
          // setSubmitLoader(false);
          console.log('response in update Category', response.data);
          if (response) {
            setPreviewUrl(response);
          }
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response.status === 400) {
            console.log('error', error.response.status, error.response.data.error);
            setIsShowUpdate(true);
            setErrorUpdate(true);
            setIsShow(true);
            setError(true);

            setErrorMessage(error.response.data.error);
            setTimeout(() => {
              setIsShowUpdate(false);
            }, 3000);
          } else {
            setErrorUpdate(false);
          }
        });
    }
  }, [categoryData]);

  // const handleImageChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   formik.setFieldValue('image', selectedFile);
  // };

  const handleImageChange = (e) => {
    setFileError('');
    const file = e.target.files[0];
    const idxDot = file.name.lastIndexOf('.') + 1;
    const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile === 'jpg' || extFile === 'png') {
      if (file.size > 1000000) {
        setFileError('Please upload a file smaller than 1 MB');
        return false;
      }
      // else {
      setLogoImage(file);
      setFileName(file.name);
      setPreviewUrl(URL.createObjectURL(file));
      // }
    } else {
      setFileError('Only jpg/jpeg, png files are allowed!');
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue('image', null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const { errors, touched, getFieldProps } = formik;
  return (
    <>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Typography variant="h6" fontWeight={'600'}>
            {categoryData ? ' Update Category' : ' Create Category'}
          </Typography>
          <Card sx={{ padding: '20px' }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <Stack spacing={3} sx={{ mt: 3 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        autoComplete="Title"
                        label="Title"
                        color="warning"
                        {...getFieldProps('Title')}
                        error={Boolean(touched.Title && errors.Title)}
                        helperText={touched.Title && errors.Title}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="Description"
                        label="Description"
                        color="warning"
                        {...getFieldProps('Description')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end" />,
                        }}
                        error={Boolean(touched.Description && errors.Description)}
                        helperText={touched.Description && errors.Description}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="Background_Color"
                        label="Background Color"
                        color="warning"
                        {...getFieldProps('Background_Color')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end" />,
                        }}
                        error={Boolean(touched.Background_Color && errors.Background_Color)}
                        helperText={touched.Background_Color && errors.Background_Color}
                      />
                    </Grid>

                    {/* TODO:: Uncomment for Image upload */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className="drop_box">
                            <Grid item xs={12} sm={12} md={12} className="mb-12">
                              <label htmlFor="UploadButton">
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  id="logoImage"
                                  onChange={handleImageChange}
                                />
                              </label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} />
                            <Grid item xs={12} sm={12} md={12}>
                              {previewURL != null ? (
                                <div className="img-section">
                                  <img src={previewURL} width="200" height="100" alt="jjjjj" />{' '}
                                </div>
                              ) : null}
                            </Grid>
                            <div className="text-align-center">
                              <p>Files Supported: JPG, PNG</p>
                            </div>
                          </div>
                          <div className="errors">{fileError}</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <LoadingButton
                        //   loading={submitLoader && true}

                        disableElevation
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ float: 'right' }}
                        sx={{
                          paddingLeft: '25px',
                          paddingRight: '25px',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                          borderRadius: '10px',
                          textTransform: 'inherit',
                          fontFamily: 'Poppins',
                          mt: '6px',
                          mb: '6px',
                          fontWeight: 500,
                          backgroundColor: '#FD771A',
                          color: '#FFFFFF!important',
                          '&:hover': {
                            backgroundColor: '#FD771A',
                            color: '#FFFFFF!important',
                          },
                        }}
                      >
                        {categoryData ? 'Update' : ' Submit'}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} />
                  </Grid>
                </Stack>
              </Form>
            </FormikProvider>
          </Card>
          <ShowMessage
            onClose={() => setIsShow(false)}
            title={errorMessage}
            type={error ? 'error' : 'success'}
            open={isShow}
          />
        </ThemeProvider>
      </Container>
    </>
  );
};

export default CreateCategory;
