import { createSlice } from '@reduxjs/toolkit';
import { addCategories,updateCategory,getCategories,deleteCategories } from '../../actions/categoryActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  category: undefined,
  error: '',
  rowcount:0,
  isLoading: false,
};

// slice or reducer
const Category = createSlice({
  name: 'Category',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseCategory(builder, addCategories);
    caseCategory(builder, updateCategory);
    caseCategory(builder, getCategories);
    caseCategory(builder, deleteCategories);
  },
});
export default Category.reducer;

const caseCategory = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.ADD_CATEGORIES)) {
      state.category = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_CATEGORIES)) {
      state.isLoading = false;
      state.category = action.payload;
     
    } else if (action.type.includes(actionTypes.GET_CATEGORIES)) {
      state.isLoading = false;
      state.category = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_CATEGORIES)) {
      state.category = action.payload;
      state.isLoading = false;
    }
  });
};
