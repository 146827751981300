import { createSlice } from '@reduxjs/toolkit';
import { addContents,updateContents,getContents,deleteContents,getContentDocs } from '../../actions/contentAction';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  contents: undefined,
  error: '',
  rowcount:0,
  isLoading: false,
};

// slice or reducer
const Content = createSlice({
  name: 'Content',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseContent(builder, addContents);
    caseContent(builder, updateContents);
    caseContent(builder, getContents);
    caseContent(builder, deleteContents);
  },
});
export default Content.reducer;

const caseContent = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.ADD_CONTENTS)) {
      state.contents = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_CONTENTS)) {
      state.isLoading = false;
      state.contents = action.payload;
     
    } else if (action.type.includes(actionTypes.GET_CONTENTS)) {
      state.isLoading = false;
      state.contents = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_CONTENTS)) {
      state.contents = action.payload;
      state.isLoading = false;
    }
  });
};
