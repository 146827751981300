import axios from 'axios';
// components

const axiosInstance = axios.create({
  // baseURL: isStage? process.env.REACT_APP_BASE_URL_STAGE : process.env.REACT_APP_BASE_URL_PROD,
  baseURL: process.env.REACT_APP_ENV === 'STAGE' ? process.env.REACT_APP_BASE_URL_STAGE : process.env.REACT_APP_BASE_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
    'Content-Language': 'browser'
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     alert(error.response?.status)
//     if (error.response?.status === 401) {
//       setTimeout(() => {
//         localStorage.clear();
//       }, 1000);
//       setTimeout(() => {
//         window.location.href = '/login';
//       }, 1000);
//     }
//   }
// );
export default axiosInstance;
