import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  InputAdornment,
  CssBaseline,
  MenuItem,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Label from '../../components/Label';
import {
  addSubCategories,
  updateSubCategory,
  getSubCategoriesImage,
} from '../../Redux-Store/actions/subcategoryAction';
import ShowMessage from '../../Component/ShowMessage';
import { getCategories, getCategoriesDdl } from '../../Redux-Store/actions/categoryActions';

import Iconify from '../../components/Iconify';

const theme = createTheme();
const CreateSubCategory = () => {
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subCategoryFormData = useLocation();

  const subCategoryData = subCategoryFormData?.state?.subCategoryData;

  console.log('subCategoryData', subCategoryData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [previewURL, setPreviewUrl] = useState(null);
  const [fileError, setFileError] = useState('');
  const [logoImage, setLogoImage] = useState('');
  const [selectedFileName, setFileName] = useState('');
  const [categoryData, setCategoryData] = useState([]);

  const { categoryId } = useParams();
  const categorySchema = Yup.object().shape({
    Title: Yup.string().required('Title is required').max(30, 'Title should not  more than 30 characters'),

    Description: Yup.string().max(30, 'Description should not more than 30 characters'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      CategoryId: subCategoryData ? subCategoryData?.categoryId : categoryId,
      Title: subCategoryData ? subCategoryData?.title : '',
      Description: subCategoryData ? subCategoryData?.description : '',
    },
    validationSchema: categorySchema,

    onSubmit: (values) => {
      const paramsData = subCategoryData
        ? {
            id: subCategoryData.id,
            categoryId: values.CategoryId,
            title: values.Title,
            description: values.Description,
            file: logoImage,
          }
        : {
            categoryId: values.CategoryId,
            title: values.Title,
            description: values.Description,
            file: logoImage,
          };

      console.log('paramsData', paramsData);
      if (subCategoryData?.id) {
        dispatch(updateSubCategory(paramsData))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update Category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);
              setErrorMessage('Category updated Successfully!!');
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);

              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addSubCategories(paramsData))
          .unwrap()
          .then((response) => {
            console.log('response of add category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);

              setErrorMessage('Category created Successfully!!');
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);

              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });

  useEffect(() => {
    // if(categoryId){
    dispatch(getCategoriesDdl())
      .unwrap()
      .then((response) => {
        if (response) {
          setCategoryData(response);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    // }
  }, []);

  useEffect(() => {
    if (subCategoryData) {
      dispatch(getSubCategoriesImage(subCategoryData?.id))
        .unwrap()
        .then((response) => {
          console.log('response in update Category', response);
          if (response) {
            setPreviewUrl(response);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [subCategoryData]);

  // const handleImageChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   formik.setFieldValue('image', selectedFile);
  // };

  const handleImageChange = (e) => {
    setFileError('');
    const file = e.target.files[0];
    const idxDot = file.name.lastIndexOf('.') + 1;
    const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile === 'jpg' || extFile === 'png') {
      if (file.size > 1000000) {
        setFileError('Please upload a file smaller than 1 MB');
        return false;
      }
      // else {
      setLogoImage(file);
      setFileName(file.name);
      setPreviewUrl(URL.createObjectURL(file));
      // }
    } else {
      setFileError('Only jpg/jpeg, png files are allowed!');
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue('image', null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const { errors, touched, getFieldProps } = formik;
  return (
    <>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Typography variant="h3" fontWeight={'600'}>
            {subCategoryData ? ' Update Sub Category' : ' Create Sub Category'}
          </Typography>
          <Card sx={{ padding: '20px' }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <Stack spacing={3} sx={{ mt: 3 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                       
                        required
                        autoComplete="Category"
                        label="Category"
                        color="warning"
                        // value={}
                        // onChange={getAllAccountId}
                        {...getFieldProps('CategoryId')}
                        error={Boolean(touched.CategoryId && errors.CategoryId)}
                        helperText={touched.CategoryId && errors.CategoryId}
                      >
                        {categoryData.map((option) => (
                          <MenuItem key={option.title} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                        <MenuItem key="" value="0">
                          Select Category
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        autoComplete="Title"
                        label="Title"
                        color="warning"
                        {...getFieldProps('Title')}
                        error={Boolean(touched.Title && errors.Title)}
                        helperText={touched.Title && errors.Title}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="Description"
                        label="Description"
                        color="warning"
                        {...getFieldProps('Description')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end" />,
                        }}
                        error={Boolean(touched.Description && errors.Description)}
                        helperText={touched.Description && errors.Description}
                      />
                    </Grid>

                    {/* TODO:: Uncomment for Image upload */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className="drop_box">
                            <Grid item xs={12} sm={12} md={12} className="mb-12">
                              <label htmlFor="UploadButton">
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  id="logoImage"
                                  onChange={handleImageChange}
                                />
                              </label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} />
                            {/* <img src="https://www.ilovepdf.com/img/ilovepdf/social/en-US/imagepdf.png" alt="img"/> */}
                            <Grid item xs={12} sm={12} md={12}>
                              {previewURL != null ? (
                                <div className="img-section">
                                  <img src={previewURL} width="200px" height="100px" alt="jjjj" />{' '}
                                </div>
                              ) : null}
                            </Grid>
                            <div className="text-align-center">
                              <p>Files Supported: JPG, PNG</p>
                            </div>
                          </div>
                          <div className="errors">{fileError}</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <LoadingButton
                        disableElevation
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ float: 'right' }}
                        sx={{
                          paddingLeft: '25px',
                          paddingRight: '25px',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                          borderRadius: '10px',
                          textTransform: 'inherit',
                          fontFamily: 'Poppins',
                          mt: '6px',
                          mb: '6px',
                          fontWeight: 500,
                          backgroundColor: '#FD771A',
                          color: '#FFFFFF!important',
                          '&:hover': {
                            backgroundColor: '#FD771A',
                            color: '#FFFFFF!important',
                          },
                        }}
                      >
                        {subCategoryData ? 'Update' : ' Submit'}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} />
                  </Grid>
                </Stack>
              </Form>
            </FormikProvider>
          </Card>
          <ShowMessage
            onClose={() => setIsShow(false)}
            title={errorMessage}
            type={error ? 'error' : 'success'}
            open={isShow}
          />
        </ThemeProvider>
      </Container>
    </>
  );
};

export default CreateSubCategory;
