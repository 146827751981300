import { useEffect, useState } from 'react';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Card, CardContent } from '@mui/material';
import { dashboardStateAction } from '../Redux-Store/actions/dashboardActions';
// components
import axiosInstance from '../api/withJWTServer';
import Page from '../components/Page';
// sections

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#e4e4e4',
  },
}));

const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px',
}));

const MainTypo = styled('div')(({ theme }) => ({
  fontWeight: 700,
  fontSize: '36px',
  color: '#07703C',
}));

export default function DashboardApp() {
  const role = localStorage.getItem('role');
  const isLogged = localStorage.getItem('isLogged');

  const { dashboardState } = useSelector((state) => ({
    dashboardState: state.dashboard.dashboardState?.data?.data[0],
  }));

  console.log('dashboardState', dashboardState);

  const dispatch = useDispatch();

  const [dashboardCount, setDashboardCount] = useState({});

  useEffect(() => {
    // dispatch(dashboardStateAction());
  }, []);
  console.log('Total Category Count:', dashboardCount?.total_category);

  useEffect(() => {
    setDashboardCount(dashboardState);
  }, [dashboardState]);

  return (
    <RootStyle>
      <Page title="Dashboard" sx={{ width: '100%', padding: '0px 10px' }}>
        <Container maxWidth="xl" sx={{ width: '100%' }}>
          <Typography style={{ fontWeight: '700', fontSize: '20px' }}>Dashboard</Typography>
          {/* <Typography style={{ fontSize: '15px', fontWeight: '400' }}>
            Here we have a summary of your business.
          </Typography> */}
        </Container>
      </Page>
    </RootStyle>
  );
}
