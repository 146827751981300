import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import ResetPassword from './sections/auth/ResetPassword';
import ValidateOTP from './sections/auth/ValidateOTP';
import Category from './pages/category/Category';
import SubCategory from './pages/subcategory/subcategory';
import CreateCategory from './pages/category/CreateCategory';
import CreateSubcategory from './pages/subcategory/CreateSubcategory';
import Contents from './pages/contents/Contents';
import CreateContents from './pages/contents/CreateContents';
import AgeGroupList from './pages/ageGroup/ageGroupList';
import CreateAgeGroup from './pages/ageGroup/createAgeGoup';
import Profile from './pages/Profile';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import MapByAgeGroup from './pages/mapByAgeGroup/MapByAgeGroup';
// ----------------------------------------------------------------------
export default function Router() {
  // const { isLogged } = useSelector((state) => ({
  //   isLogged: state.authentication.isLogged,
  // }));
  const isLogged = localStorage.getItem('isLogged');
  console.log('isLogged', isLogged);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isLogged ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'category', element: <Category /> },
        { path: 'create_category', element: <CreateCategory /> },
        { path: 'sub_category/:categoryId', element: <SubCategory /> },
        { path: 'sub_category', element: <SubCategory /> },
        { path: 'createSubcategory/:categoryId', element: <CreateSubcategory /> },
        { path: 'createSubcategory', element: <CreateSubcategory /> },
        { path: 'contents', element: <Contents /> },
        { path: 'create_contents', element: <CreateContents /> },
        { path: 'age_group', element: <AgeGroupList /> },
        { path: 'create_agegroup', element: <CreateAgeGroup /> },
        { path: 'profile', element: <Profile /> },
        { path: 'map_by_age_group', element: <MapByAgeGroup /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'resetpassword', element: <ResetPassword /> },
        { path: 'validateotp', element: <ValidateOTP /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
  return routes;
}
