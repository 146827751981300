import { useEffect, useState, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  InputAdornment,
  CssBaseline,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Avatar,

  Divider,
  Box,
  Button,
} from '@mui/material';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFileSharp';
import DocumentFileIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDropzone } from 'react-dropzone';
import CommonDeleteBox from '../../Component/ModelBox/CommonDeleteBox';
import Label from '../../components/Label';
import { getCategories, getCategoriesDdl } from '../../Redux-Store/actions/categoryActions';
import { getSubCategories, getSubCategoriesDdl } from '../../Redux-Store/actions/subcategoryAction';
import { getContents, addContents, updateContents, getContentDocs,deleteContentFile } from '../../Redux-Store/actions/contentAction';
import ShowMessage from '../../Component/ShowMessage';
import CONTENT_FILE_TYPE from '../../Constants/fileTypeConstants';
import Iconify from '../../components/Iconify';


const theme = createTheme();
const CreateContents = () => {

  const dropzoneStyle = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contentFormData = useLocation();
  const contentData = contentFormData?.state?.contentData;

  console.log('contentData', contentData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [previewURL, setPreviewUrl] = useState(null);
  const [audioFileError, setAudioFileError] = useState("");
  const [videoFileError, setVideoFileError] = useState("");
  const [fileError, setFileError] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [selectedFileName, setFileName] = useState("");
  const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
  const [selectedVideoFiles, setSelectedVideoFiles] = useState([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [uploadedImageFiles, setUpoloadedImageFiles] = useState([]);
  const [uploadedAudioFiles, setUpoloadedAudioFiles] = useState([]);
  const [uploadedVideoFiles, setUpoloadedVideoFiles] = useState([]);
  const [selectedFileTypeToDelete, setSelectedFileType] = useState(null);
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null);
  const [open, setOpen] = useState(false);


  const contentSchema = Yup.object().shape({

    // Category: Yup.string().required('Category is required'),

    // SubCategory: Yup.string().required('SubCategory is required'),

    Title: Yup.string()
      .required('Title is required')
      .max(30, 'Title should not  more than 30 characters'),

    Description: Yup.string()
      .max(30, 'Description should not more than 30 characters'),

    Metadata: Yup.string()
      .max(30, 'Title should not  more than 30 characters'),

  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: contentData ? contentData?.title : '',
      Description: contentData ? contentData?.description : '',
      CategoryId: contentData ? contentData?.categoryId : '',
      SubCategory: contentData ? contentData?.subCategoryId : '',
      Metadata: contentData ? contentData?.Metadata : '',

    },
    validationSchema: contentSchema,
    onSubmit: (values) => {
      setSubmitLoader(true);
      const formData = new FormData();
    
      if (contentData) {
        formData.append(`id`, contentData.id);
      }
      formData.append(`CategoryId`, values.CategoryId);
      formData.append(`SubCategoryId`, values.SubCategory);
      formData.append(`Metadata`, values.Metadata);
      formData.append(`title`, values.Title);
      formData.append(`description`, values.Description);
      selectedAudioFiles.forEach((file, index) => {
        formData.append(`ContentDocument.AudioFiles`, file);
        return file;
      });

      selectedVideoFiles.forEach((file) => {
        formData.append(`ContentDocument.VideoFiles`, file);
        return file;
      });

      selectedImageFiles.map((file) => {
        formData.append(`ContentDocument.ImageFiles`, file);
        return file;
      });

      console.log('paramsData', formData);
      if (contentData?.id) {
        dispatch(updateContents(formData))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update Category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);
              setErrorMessage("Content updated Successfully!!");
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);

              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addContents(formData))
          .unwrap()
          .then((response) => {
            console.log('response of add category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);

              setErrorMessage("Content created Successfully!!");
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);

              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });

  useEffect(() => {
    if (contentData?.contentDocument) {
      setUpoloadedImageFiles(contentData?.contentDocument?.imageFiles);
      setUpoloadedAudioFiles(contentData?.contentDocument?.audioFiles);
      setUpoloadedVideoFiles(contentData?.contentDocument?.videoFiles);
    }
    if (contentData?.categoryId) {
      bindSubCategory(contentData?.categoryId);
    }
  }, [contentData]);

  useEffect(() => {
    // if(categoryId){
    dispatch(getCategoriesDdl()).unwrap()
      .then((response) => {
        if (response) {
          setCategoryData(response);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    // }
  }, []);
  const onAudioDrop = useCallback(acceptedFiles => {
    const updatedImages = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size; // File size in bytes
      // const maxSizeInBytes = 10 * 1024 * 1024; // 5MB
      // if (fileSize <= maxSizeInBytes) {
      //   setAudioFileError();
      // } else {
      //   setAudioFileError('*File size exceeds. Each file should be max 5mb');
      // }

      if (['mpeg', 'wav', 'mp3'].includes(fileExtension)) {
        setAudioFileError();
      } else {
        setAudioFileError('*Select Audio files only');
      }
      return ['mpeg', 'wav', 'mp3'].includes(fileExtension);
    });
    setSelectedAudioFiles((prevFiles) => [...prevFiles, ...updatedImages]);
  }, []);

  const acceptedAudioTypes = ['audio/mpeg', 'audio/wav', 'audio/mp3'];
  const { getRootProps: getRootPropsForAudio, getInputProps: getInputPropsForAudio } = useDropzone({
    onDrop: onAudioDrop,
    multiple: true,

  });

  const onVideoDrop = useCallback(acceptedFiles => {
    const updatedFile = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size; // File size in bytes
      // const maxSizeInBytes = 10 * 1024 * 1024; // 5MB
      // if (fileSize <= maxSizeInBytes) {
      //  // setImageError();
      // } else {
      //  // setImageError('File size exceeds. Each file should be max 5mb');
      // }

      if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
        setVideoFileError();
      } else {
        setVideoFileError('*Select valid Video file only');
      }
      return ['mp4', 'webm', 'ogg'].includes(fileExtension);
    });
    setSelectedVideoFiles((prevFiles) => [...prevFiles, ...updatedFile]);
  }, []);

  const acceptedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];
  const { getRootProps: getRootPropsForVideo, getInputProps: getInputPropsForVideo } = useDropzone({
    onDrop: onVideoDrop,
    multiple: true
  });

  const onImageDrop = useCallback(acceptedFiles => {
    const updatedFile = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size; // File size in bytes
      const maxSizeInBytes = 10 * 1024 * 1024; // 5MB
      if (fileSize <= maxSizeInBytes) {
        setFileError();
      } else {
        setFileError('File size exceeds. Each file should be max 5mb');
      }

      if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
        setFileError();
      } else {
        setFileError('*Select valid image file only');
      }
      return ['png', 'jpg', 'jpeg'].includes(fileExtension) && fileSize <= maxSizeInBytes;
    });
    setSelectedImageFiles((prevFiles) => [...prevFiles, ...updatedFile]);
  }, []);

  const acceptedImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  const { getRootProps: getRootPropsForImage, getInputProps: getInputPropsForImage } = useDropzone({
    onDrop: onImageDrop,
    multiple: true
  });

  useEffect(() => {
    if (contentData) {
      dispatch(getContentDocs(contentData?.id))
        .unwrap()
        .then((response) => {
          // setSubmitLoader(false);
          console.log('response in update Category', response.data);
          if (response) {
            setPreviewUrl(response);
          }
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response.status === 400) {
            console.log('error', error.response.status, error.response.data.error);
            setIsShowUpdate(true);
            setErrorUpdate(true);
            setIsShow(true);
            setError(true);

            setErrorMessage(error.response.data.error);
            setTimeout(() => {
              setIsShowUpdate(false);
            }, 3000);
          } else {
            setErrorUpdate(false);
          }
        });
    }
  }, [contentData]);


  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    bindSubCategory(selectedCategory);
    formik.handleChange(event);

  };

  const bindSubCategory = (categoryId) => {
    if (categoryId) {
      dispatch(getSubCategoriesDdl(categoryId)).unwrap()
        .then((response) => {
          if (response) {
            console.log('subcategory', response);
            setSubCategoryData(response);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }

  // To delete selected file before upload
  const removeAudioFile = file => {
    setSelectedAudioFiles(prevFiles => prevFiles.filter(f => f !== file));
  };

  const removeVideoFile = file => {
    setSelectedVideoFiles(prevFiles => prevFiles.filter(f => f !== file));
  };

  const removeImageFile = file => {
    setSelectedImageFiles(prevFiles => prevFiles.filter(f => f !== file));
  };

  // To delete uploaded file from server
  const deleteAudioFile = (file,fileType) => {
    console.log("File to delet",file);
    setSelectedFileType(fileType);
    setSelectedFileToDelete(file);
    setOpen(!open);
  };

  const deleteVideoFile = (file,fileType) => {
    console.log("File to delet",file);
    setSelectedFileType(fileType);
    setSelectedFileToDelete(file);
    setOpen(!open);
  };

  const deleteImageFile = (file,fileType) => {
    console.log("File to delet",file);
    setSelectedFileType(fileType);
    setSelectedFileToDelete(file);
    setOpen(!open);
  };
  

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const handleDeleteFile = (file) => {
    
    if(selectedFileTypeToDelete != null && selectedFileToDelete != null){
      dispatch(deleteContentFile({contentId:contentData?.id, documentId: selectedFileToDelete?.documentId,documentType:selectedFileTypeToDelete }))
      .unwrap()
      .then((response) => {
        console.log('response in delete category', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          setErrorMessage('Record deleted Successfully!!');
          if(selectedFileTypeToDelete === CONTENT_FILE_TYPE.Audio){
            setUpoloadedAudioFiles(prevFiles => prevFiles.filter(f => f !== selectedFileToDelete));
          }else if(selectedFileTypeToDelete === CONTENT_FILE_TYPE.Video){
            setUpoloadedVideoFiles(prevFiles => prevFiles.filter(f => f !== selectedFileToDelete));
          }else{
            setUpoloadedImageFiles(prevFiles => prevFiles.filter(f => f !== selectedFileToDelete));
          }
          
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
    }
   
  };

  const handleDeleteCancel = () => {
    setOpen(!open);
  };

  const { errors, touched, getFieldProps } = formik;
  return (
    <>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {open ? (
          <CommonDeleteBox
            openDialog={open}
            handleDeleteYes={handleDeleteFile}
            handleClose={handleDeleteCancel}
            title="Content Document"
            description="Are you sure you want to delete Content ?"
          />
        ) : null}

          <Typography variant="h6" fontWeight={'600'}>
            {contentData ? ' Update  Content' : ' Create Content'}
          </Typography>
          <Card sx={{ padding: '20px' }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <Stack spacing={3} sx={{ mt: 3 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        select
                        required
                        autoComplete="CategoryId"
                        label="Category"
                        color="warning"
                        name='CategoryId'
                        value={formik.values.CategoryId}
                        onChange={handleCategoryChange}
                        error={Boolean(touched.CategoryId && errors.CategoryId)}
                        helperText={touched.CategoryId && errors.CategoryId}
                      >
                        {categoryData.map((option) => (
                          <MenuItem key={option.title} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                        <MenuItem key="" value="0">
                          Select Category
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        select
                        required
                        autoComplete="SubCategory"
                        label="Sub Category"
                        color="warning"
                        value={formik.values.SubCategory}
                        // onChange={getAllAccountId}
                        {...getFieldProps('SubCategory')}
                        error={Boolean(touched.SubCategory && errors.SubCategory)}
                        helperText={touched.SubCategory && errors.SubCategory}
                      >
                        {subCategoryData.map((option) => (
                          <MenuItem key={option.title} value={option.id}>
                            {option.title}
                          </MenuItem>
                        ))}
                        <MenuItem key="" value="0">
                          Select Category
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        autoComplete="Title"
                        label="Title"
                        color="warning"
                        {...getFieldProps('Title')}
                        error={Boolean(touched.Title && errors.Title)}
                        helperText={touched.Title && errors.Title}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="Description"
                        label="Description"
                        color="warning"
                        {...getFieldProps('Description')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end" />,
                        }}
                        error={Boolean(touched.Description && errors.Description)}
                        helperText={touched.Description && errors.Description}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="Metadata"
                        label="Metadata"
                        color="warning"
                        {...getFieldProps('Metadata')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end" />,
                        }}
                        error={Boolean(touched.Metadata && errors.Metadata)}
                        helperText={touched.Metadata && errors.Metadata}
                      />
                    </Grid>

                    {/* TODO:: Uncomment for Image upload */}

                    <Grid container xs={12} sm={12} md={12} sx={{
                      boxSizing: 'border-box', position: 'relative',
                      padding: '15px',
                      background: '#F8F8F8',
                      border: '1px solid #D7D7D7',
                      marginTop: '30px',
                      marginLeft: '30px',
                      borderRadius: '4px'
                    }}>
                      <Grid item xs={3} sm={3} md={3}>
                        <div className="drop_box">
                          <Grid item xs={12} sm={12} md={12} className="mb-12">
                            <label htmlFor="UploadButton">
                              <Label>Audio File</Label>
                              <div {...getRootPropsForAudio()} style={dropzoneStyle}>
                                <input {...getInputPropsForAudio()} multiple />
                                <p>Click to select files</p>
                              </div>
                            </label>
                          </Grid>
                        </div>
                        <div className="warning">{audioFileError}</div>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <List style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
                          {uploadedAudioFiles?.map(file => (<ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => deleteAudioFile(file,CONTENT_FILE_TYPE.Audio)} />
                              </IconButton>}>
                            <ListItemAvatar>
                              <Avatar>
                                <AudioFileIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={file.fileName}
                            />
                          </ListItem>))}
                          {selectedAudioFiles?.map(file => (<ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => removeAudioFile(file)} />
                              </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <AudioFileIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={file.name}
                            />
                          </ListItem>))}
                        </List>
                      </Grid>
                    </Grid>


                    <Grid container xs={12} sm={12} md={12} sx={{
                      boxSizing: 'border-box', position: 'relative',
                      padding: '15px',
                      background: '#F8F8F8',
                      border: '1px solid #D7D7D7',
                      marginTop: '30px',
                      marginLeft: '30px',
                      borderRadius: '4px'
                    }}>
                      <Grid item xs={3} sm={3} md={3}>
                        <div className="drop_box">
                          <Grid item xs={12} sm={12} md={12} className="mb-12">
                            <label htmlFor="UploadButton">
                              <Label>Video File</Label>
                              <div {...getRootPropsForVideo()} style={dropzoneStyle}>
                                <input {...getInputPropsForVideo()} multiple />
                                <p>Click to select files</p>
                              </div>
                            </label>
                          </Grid>
                        </div>
                        <div className="warning">{videoFileError}</div>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        <List style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px'}}>
                          {uploadedVideoFiles?.map(file => (<ListItem 
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => deleteVideoFile(file,CONTENT_FILE_TYPE.Video)} />
                              </IconButton>}>
                            <ListItemAvatar>
                              <Avatar>
                                <AudioFileIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText 
                              primary={file.fileName}
                              primaryTypographyProps={{ style: { wordWrap: 'break-word' } }}
                            />
                          </ListItem>))}
                          {selectedVideoFiles?.map(file => (<ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => removeVideoFile(file)} />
                              </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <AudioFileIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={file.name}
                            />
                          </ListItem>))}
                        </List>
                      </Grid>
                    </Grid>


                    {/* <Grid item xs={12} sm={12} md={4}>
               
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                     
                    </Grid>
                  </Grid>
                </Grid> */}


                    <Grid container xs={12} sm={12} md={12} sx={{
                      boxSizing: 'border-box', position: 'relative',
                      padding: '15px',
                      background: '#F8F8F8',
                      border: '1px solid #D7D7D7',
                      marginTop: '30px',
                      marginLeft: '30px',
                      borderRadius: '4px'
                    }}>
                      <Grid item xs={3} sm={3} md={3}>
                        <Label>Image File</Label>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className="drop_box">
                              <Grid item xs={12} sm={12} md={12} className="mb-12">
                                <label htmlFor="UploadButton">
                                  <div {...getRootPropsForImage()} style={dropzoneStyle}>
                                    <input {...getInputPropsForImage()} multiple />
                                    <p>Click to select files</p>
                                  </div>
                                </label>

                              </Grid>
                              <Grid item xs={12} sm={12} md={12} />

                            </div>
                            <div className="errors">{fileError}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <List style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
                          {uploadedImageFiles?.map(file => (<ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => deleteImageFile(file,CONTENT_FILE_TYPE.Image)} />
                              </IconButton>}>
                            <ListItemAvatar>
                              <Avatar>
                                <DocumentFileIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={file.fileName}
                            />
                          </ListItem>))}
                          {selectedImageFiles?.map(file => (<ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => removeImageFile(file)} />
                              </IconButton>}>
                            <ListItemAvatar>
                              <Avatar>
                                <DocumentFileIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={file.name}
                            />
                          </ListItem>))}
                        </List>
                      </Grid>
                    </Grid>


                    <Grid item xs={12}>
                      <LoadingButton
                        //   loading={submitLoader && true}
                        disableElevation
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ float: 'right' }}
                        sx={{
                          paddingLeft: "25px",
                          paddingRight: "25px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          borderRadius: "10px",
                          textTransform: "inherit",
                          fontFamily: "Poppins",
                          mt: "6px",
                          mb: "6px",
                          fontWeight: 500,
                          backgroundColor: "#FD771A",
                          color: "#FFFFFF!important",
                          "&:hover": {
                            backgroundColor: "#FD771A",
                            color: "#FFFFFF!important",
                          },
                        }}
                      >
                        {contentData ? 'Update' : ' Submit'}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} />
                  </Grid>
                </Stack>
              </Form>
            </FormikProvider>
          </Card>
          <ShowMessage
            onClose={() => setIsShow(false)}
            title={errorMessage}
            type={error ? 'error' : 'success'}
            open={isShow}
          />
        </ThemeProvider>
      </Container>

    </>

  );
};

export default CreateContents;
