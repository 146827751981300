import { createSlice } from '@reduxjs/toolkit';
import { addSubCategories,updateSubCategory,getSubCategories,deleteSubCategories } from '../../actions/subcategoryAction';
import { actionTypes } from '../../actionTypes';

// redux state
const initialState = {
  subCategory: undefined,
  error: '',
  rowcount:0,
  isLoading: false,
};

// slice or reducer
const SubCategory = createSlice({
  name: 'SubCategory',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseSubCategory(builder, addSubCategories);
    caseSubCategory(builder, updateSubCategory);
    caseSubCategory(builder, getSubCategories);
    caseSubCategory(builder, deleteSubCategories);
  },
});
export default SubCategory.reducer;

const caseSubCategory = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.ADD_SUB_CATEGORIES)) {
      state.subCategory = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_CATEGORIES)) {
      state.isLoading = false;
      state.subCategory = action.payload;
     
    } else if (action.type.includes(actionTypes.GET_SUB_CATEGORIES)) {
      state.isLoading = false;
      state.subCategory = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_CATEGORIES)) {
      state.subCategory = action.payload;
      state.isLoading = false;
    }
  });
};
