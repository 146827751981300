import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Container, Typography, IconButton, InputAdornment, Card, Chip,Box,Grid,CssBaseline } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled,ThemeProvider,createTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { styles } from "../../Styles/Login";
import Companylogo from "../../Assets/Images/Companylogo.png";
// component
import Iconify from '../../components/Iconify';
import apis from '../apis/apis';
import { HandleExceptionWithSecureCatch } from '../../Redux-Store/actions/CombineCatch';
import Page from '../../components/Page';
// HOOKS
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------
const theme = createTheme();
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    position: 'relative',
    background: '#165f91',
  },

  height: '100vh',
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  height: '95vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 'auto'),
}));

const SubSection = styled(Card)(({ theme }) => ({
  width: 340,
  maxWidth: 350,
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0, 'auto'),
  boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
  padding: '50px',
}));

export default function ResetPassword() {
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [uuid, setuuid] = useState('');
  const [userName, setUserName] = useState('');
  const [otp, setActiveCode] = useState('');

  useEffect(() => {
    // Get the UUID value from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    const username = localStorage.getItem('userName');
    const otp = localStorage.getItem('activeCode');
    setUserName(username);
    setActiveCode(otp);
    setuuid(uuid);
  }, []);

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      // .min(8, 'Password must be at least 8 characters long.')
      // .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter.')
      // .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter.')
      // .matches(/^(?=.*\d)/, 'Password must contain at least one numeric value.')
      // .matches(/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/, 'Password must contain at least one special character.'),
      ,
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      email: userName,
      newPassword: '',
      confirmPassword: '',
      activeCode: otp
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      const params = {
      email: userName,
      newPassword: value.password,
      confirmPassword: value.confirmpassword,
      activeCode: otp
      };
      apis
        .resetPassword(params)
        .then((response) => {
          dispatch(HandleExceptionWithSecureCatch(response));
          navigate('/dashboard', { replace: true });
        })
        .catch((err) => {});
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const ChipSection = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 20,
    right: 20,
  }));
  return (
    <>
      <Page title="Login">
      <ThemeProvider theme={theme}>
        <Box sx={{
          width: "70%",
          mx: "auto",
          border: "none",
          boxShadow: "none!important",
        }}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "40vh",
                width: "100%",
                left: "30px",
              }}
            >
              {/* <img src={Orangebits} alt="Obsimg" /> */}
              <img src={Companylogo} alt="logo" style={{ width: "45%" }} />
              <h1 className="ms-3">
                One Place By <br />
                Spinoff
              </h1>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box>
            <>
              <Grid>
              <Box {...styles.parentBox}>
                <Typography
                  component="h1"
                  variant="h5"
                  {...styles.parentHeading}
                >
                  RESET PASSWORD
                </Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        color="warning"
                        // label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                     
                      <TextField
                        fullWidth
                        autoComplete="confirm-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        // label="Confirm Password"
                        {...getFieldProps('confirmpassword')}
                        label="Confirm Password"
                        color="warning"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowConfirmPassword} edge="end">
                                <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                        helperText={touched.confirmpassword && errors.confirmpassword}
                      />
                    </Stack>
                    <br />
                    <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "25px",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="warning"
                     
                      style={{
                        // textTransform: "inherit",
                        fontFamily: "Outfit",
                        borderRadius: "20px",

                        fontWeight: "bold",
                        height: "40px",
                      }}
                      {...styles.parentButton}
                      // className={classes.submit}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                    
                  </Form>
                </FormikProvider>
                  </Box>
                 
                </Grid>
        </>
            </Box>
          </Grid>
        </Grid>
        </Box>
      </ThemeProvider>
      </Page>
    </>
  );
}
