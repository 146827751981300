import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

// Get Category
export const getContents = createAsyncThunk(actionTypes.GET_CONTENTS, async (params, thunkAPI) => {

  const mainUrl = `/kidsContent/all`;
  const url = params?.filterData
     ? `${mainUrl}?&cateagoryId=${params?.filterData?.categoryId}&subCategoryId=${params?.filterData?.subCategoryId}&Query=${params?.filterData?.Query}&Top=${params?.paginationModel?.pageSize}&Skip=${params?.paginationModel.page+1}`
     : `${mainUrl}`;

  try {
 
    const response = await axiosInstance.get(`${url}`);
    console.log('response of subcategories', response);

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});


// Add Contents
export const addContents = createAsyncThunk(
  actionTypes.ADD_CONTENTS,

  async (params, thunkAPI) => {
    console.log('paramshghgf', params);
    try {
        const response = await axiosInstance.post(`/kidsContent/create`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
           return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Update Contents
export const updateContents = createAsyncThunk(actionTypes.UPDATE_CONTENTS, async (params,id,thunkAPI) => {
 
  console.log("paramsubcategory",params.get(`id`));
  const contentId = params.get(`id`);
  try {
    const response = await axiosInstance.put(`/kidsContent/update/${contentId}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response Category update', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Delete Content Files
export const deleteContentFile = createAsyncThunk(actionTypes.DELETE_CONTENT_DOCS, async (params, thunkAPI) => {
  console.log('paramsmmmmdelete', params);

  try {
    const response = await axiosInstance.delete(`/kidsContent/delete/${params?.contentId}/${params.documentId}/${params.documentType}`);
    console.log('response of Delete docs', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Delete Content Files
export const deleteContents = createAsyncThunk(actionTypes.DELETE_CONTENT, async (params, thunkAPI) => {
  console.log('paramsmmmmdelete', params);

  try {
    const response = await axiosInstance.delete(`/kidsContent/delete/${params.id}`);
    console.log('response of Delete', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get Content Documents 
export const getContentDocs = createAsyncThunk(actionTypes.DOWNLOAD_CONTENT_DOCS, async (params, thunkAPI) => {
  try {
    console.log("paramid")
   // const response = await axiosInstance.get(`/category/image/${params}`);
    const imgUrl = await `${axiosInstance.getUri()}subCategory/download/${params}`;
    return imgUrl;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
