export const styles = {
  parentBox: {
    sx: {
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.5)",
      borderRadius: "25px",
      // boxShadow: "5",
      width: "100%",
      // height: "75vh",
      p: 5,
      marginX: "auto",
      mt: "7rem",
    },
  },
  parentHeading: {
    sx: {
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      fontFamily: "Outfit",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "54px",
      p: 0,
      m: 0,
      // fontFamily: "sans-serif",
    },
  },
  parentHeading2: {
    sx: {
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "8px",
      p: 0,
      marginLeft: "1%",
      // fontFamily: "sans-serif",
    },
  },
  parentButton: {
    sx: {
      p: 1,
      background: "#FD771A",
      marginTop: "20px",
      // fontFamily: "sans-serif",
      // fontWeight: "bold",
      width: "35%",
      borderRadius: "50%",
    },
  },

  parentAppbar: {
    sx: {
      background: "#F38D20",
      p: 0,
    },
  },
};
