// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('ic:baseline-space-dashboard'),
    isReviewer: true,
  },

  {
    title: 'Master',
    path: '',
    icon: getIcon('ic:round-groups-2'),
    children: [
      {
        title: 'Categories',
        path: '/dashboard/category',
        icon: getIcon('ic:baseline-apps'),
        isReviewer: false,
      },

      {
        title: 'Sub Categories',
        path: '/dashboard/sub_category',
        icon: getIcon('ic:baseline-apps'),
        isReviewer: false,
      },
      {
        title: 'Contents',
        path: '/dashboard/contents',
        icon: getIcon('ic:round-person-2'),
        isReviewer: false,
      },
      {
        title: 'Age Group',
        path: '/dashboard/age_group',
        icon: getIcon('ic:round-groups-2'),
        isReviewer: false,
      },
    ],
  },
  {
    title: 'Map Age Group',
    path: 'map_by_age_group',
    icon: getIcon('ic:baseline-supervised-user-circle'),
    isReviewer: true,
  },
  // {
  //   title: 'Manage Subscriptions',
  //   path: '/dashboard/subscription',
  //   icon: getIcon('ic:round-verified-user'),
  //   isReviewer: false,
  // },

  // {
  //   title: 'Matrimony',
  //   path: '/dashboard/matrimony',
  //   icon: getIcon('ic:round-diamond'),
  //   isReviewer: false,
  //   children: [
  //     {
  //       title: 'Candidates',
  //       path: '/dashboard/matrimony/matrimony_candidates',
  //     },
  //     {
  //       title: 'Subscription Plan',
  //       path: '/dashboard/matrimony/subscription_plan',
  //     },
  //     {
  //       title: 'Master',
  //       path: '/dashboard/matrimony/master',
  //     },
  //   ],
  // },
  // {
  //   title: 'Settings',
  //   path: '/dashboard/settings',
  //   icon: getIcon('ic:baseline-settings'),
  //   isReviewer: false,
  // },
];

export default navConfig;
