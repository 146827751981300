import Server from '../../api';

const forgotPassword = async(params) =>{
    const response = await Server.post('/auth/resetPassword/sendOTP', params)
    return response;
}

const resetPassword = async(params)=>{
    const response = await Server.post('/auth/resetPassword', params);
    return response;
}

const validateotp = async(params) =>{
    const response = await Server.post('/auth/resetPassword/verifyOTP', params)
    return response;
}

const apis = {
    forgotPassword,
    resetPassword,
    validateotp
}

export default apis;