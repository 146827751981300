import React from 'react';
import { Grid, Typography, Button, TextField, InputAdornment, Box } from '@mui/material';

import { Link as ButtonLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const PagesTopBar = (props) => {
  /* Rectangle 13 */

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
      sx={{
        background: '#F8F8F8',
        border: '1px solid #D7D7D7',
        borderRadius: '5px',
        padding: '5px',
      }}
    >
      {/* Code for title */}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography fontSize={'20px'} fontWeight={'600'}>
          {props.title}
        </Typography>
      </Grid>
      {/* Code for Add Button */}
      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Box>
          {props.Add ? (
            <Grid item xs={12} sm={6} md={2} lg={2} >
              <ButtonLink to={props.link}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    width: '80px',
                    height: '40px',
                    bgcolor: '#FD771A',
                    margin:'5px',
                    fontSize: '12px',
                    padding: '0px 10px !important',
                    '&:hover': {
                      background: '#FD771A',
                      opacity: 0.9,
                    },
                  }}
                  startIcon={<Add />}
                >
                  {props.btnText}
                </Button>
              </ButtonLink>
            </Grid>
          ) : (
            ''
          )}
        </Box>
      </Grid>
      {/* Code for Search Bar */}
      <Grid item xs={12} sm={6} md={3} lg={6} >
        <Box>
          <TextField
            id="searchField"
            name="searchField"
            size="small"
            placeholder="Search here..."
            value={props.searchValue}
            autoComplete="search"
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '40px !important',
                background: 'white',
                marginTop: '6px',
              },
            }}
            onChange={props.handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      {/* Code for Reset and Search buttons */}
      <Grid item xs={6} sm={6} md={3} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          variant="contained"
          size="small"
          onClick={props.resetFilter}
          sx={{
            width: '90px',
            height: '40px',
            bgcolor: '#229954',
            fontSize: '12px',
            margin: '5px',
            padding: '0px 10px !important',
            '&:hover': {
              background: '#229954',
              opacity: 0.9,
            },
          }}
          startIcon={<AutorenewIcon />}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={props.handleFilter}
          sx={{
            width: '90px',
            height: '40px',
            bgcolor: '#229954',
            fontSize: '12px',
            margin: '5px',
            padding: '0px 10px !important',
            '&:hover': {
              background: '#229954',
              opacity: 0.9,
            },
          }}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default PagesTopBar;
