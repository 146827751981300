import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { styled,ThemeProvider,createTheme } from '@mui/material/styles';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  InputAdornment,
  CssBaseline,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Label from '../../components/Label';
import { addAgeGroups, updateAgeGroup,getAgeGroupImage } from '../../Redux-Store/actions/ageGroupActions';
import ShowMessage from '../../Component/ShowMessage';

import Iconify from '../../components/Iconify';


const theme = createTheme();
const CreateAgeGroup = () => {
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ageGroupFormData = useLocation();

  const ageGroupData = ageGroupFormData?.state?.ageGroupData;

 

  console.log('ageGroupData', ageGroupData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [previewURL, setPreviewUrl] = useState(null);
  const [fileError, setFileError] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [selectedFileName, setFileName] = useState("");
  const categorySchema = Yup.object().shape({
    Title: Yup.string()
      .required('Title is required')
      .min(2, 'Title should not less than 2 characters')
      .max(30, 'Title should not  more than 30 characters'),

    Description: Yup.string()
   
      .matches(/^[A-Za-z0-9 ]+$/, ' Special charators not allowed')
      // .required('Description is required')
      // .min(2, 'Description should not less than 2 characters')
      .max(30, 'Description should not more than 30 characters'),

      DescriptionLong: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special charators not allowed')
      // .required('Description is required')
      // .min(2, 'Description should not less than 2 characters')
      .max(100, 'Description should not more than 100 characters'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: ageGroupData ? ageGroupData?.title : '',
      Description: ageGroupData ? ageGroupData?.shortDescription : '',
      DescriptionLong:ageGroupData ? ageGroupData?.longDescription : '',
    },
    validationSchema: categorySchema,

    onSubmit: (values) => {
      console.log('value', values);
      setSubmitLoader(true);

      const paramsData = ageGroupData
        ? {
            id: JSON.parse(ageGroupData.id),
            title: values.Title,
            shortDescription: values.Description,
            longDescription: values.DescriptionLong,
            file:logoImage
          }
        : {
            title: values.Title,
            shortDescription: values.Description,
            longDescription: values.longDescription,
            file:logoImage
          };
          console.log('paramsData', paramsData);
      if (ageGroupData?.id) {
        dispatch(updateAgeGroup(paramsData))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update Category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);
              setErrorMessage("Category updated Successfully!!");
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);
             
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addAgeGroups(paramsData))
          .unwrap()
          .then((response) => {
            console.log('response of add category', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setIsShow(true);
              setError(false);
             
              setErrorMessage("Age Group created Successfully!!");
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setIsShow(true);
              setError(true);
             
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });

  useEffect(() => {
    if(ageGroupData){
      dispatch(getAgeGroupImage(ageGroupData?.id))
      .unwrap()
      .then((response) => {
        // setSubmitLoader(false);
        console.log('response in update Category', response.data);
        if (response) {
          setPreviewUrl(response);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    }
   }, [ageGroupData]);

  // const handleImageChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   formik.setFieldValue('image', selectedFile);
  // };

  const handleImageChange = (e) => {
    setFileError("");
    const file = e.target.files[0];
    const idxDot = file.name.lastIndexOf(".") + 1;
    const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
    if (extFile === "jpg" || extFile === "png") {
    
      if (file.size > 1000000) {
        setFileError("Please upload a file smaller than 1 MB");
        return false;
      } 
      // else {
        setLogoImage(file);
        setFileName(file.name);
        setPreviewUrl(URL.createObjectURL(file));
      // }
    } else {
      setFileError("Only jpg/jpeg, png files are allowed!");
    }
   
  };

  const handleRemoveImage = () => {
    formik.setFieldValue('image', null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const { errors, touched, getFieldProps } = formik;
  return (
    <>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <Typography variant="h6" fontWeight={'600'}>
          {ageGroupData ? ' Update Age Group' : ' Create Age Group'}
        </Typography>
        <Card sx={{ padding: '20px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Stack spacing={3} sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      autoComplete="Title"
                      label="Title"
                      color="warning"
                      {...getFieldProps('Title')}
                      error={Boolean(touched.Title && errors.Title)}
                      helperText={touched.Title && errors.Title}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="Description"
                      label="Description"
                      color="warning"
                      {...getFieldProps('Description')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.Description && errors.Description)}
                      helperText={touched.Description && errors.Description}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type='textarea'
                      autoComplete="DescriptionLong"
                      label="Long Description"
                      color="warning"
                      {...getFieldProps('DescriptionLong')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.DescriptionLong && errors.DescriptionLong)}
                      helperText={touched.DescriptionLong && errors.DescriptionLong}
                    />
                  </Grid>

                  {/* TODO:: Uncomment for Image upload */}
                  <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="drop_box">
                        <Grid item xs={12} sm={12} md={12} className="mb-12">
                          <label htmlFor="UploadButton">
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              id="logoImage"
                              onChange={handleImageChange}
                           
                            />
                           
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}/>
                        <Grid item xs={12} sm={12} md={12}>
                          {previewURL != null ? (
                            <div className="img-section">
                              <img src={previewURL} width="200" height="100" alt='' />{" "}
                            </div>
                          ) : null}
                        </Grid>
                        <div className="text-align-center">
                          <p>Files Supported: JPG, PNG</p>
                        </div>
                      </div>
                      <div className="errors">{fileError}</div>
                    </Grid>
                  </Grid>
                </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      //   loading={submitLoader && true}
                     
                    disableElevation
                   
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ float: 'right' }}
                      sx={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "10px",
                        textTransform: "inherit",
                        fontFamily: "Poppins",
                        mt: "6px",
                        mb: "6px",
                        fontWeight: 500,
                        backgroundColor: "#FD771A",
                        color: "#FFFFFF!important",
                        "&:hover": {
                          backgroundColor: "#FD771A",
                          color: "#FFFFFF!important",
                        },
                      }}
                    >
                      {ageGroupData ? 'Update' : ' Submit'}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={5} />
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
        <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
        </ThemeProvider>
      </Container>
    
    </>
    
  );
};

export default CreateAgeGroup;
