export const styles = {
  appBar: {
    sx: {
      // m: 1,
      mt: 0,
      pb: 2,
      marginLeft: "500px",
      py: 2,
      // boxShadow: 3,
      // borderBottom: "1px solid rgb(177, 197, 178, .3)",
      // border: "1px solid red",
    },
  },
  childBox: {
    sx: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      flex: 1,
      width: "100%",
      flexWrap: "wrap",
    },
  },
  subChildBox: {
    sx: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  divider: {
    sx: {
      mr: 2,
      ml: 2,
      height: "50px",
      alignSelf: "center",
      display: "flex",
    },
  },
  drawerHeader: {
    sx: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      p: 1,
      m: 1,
      mr: 3,
      // border:"1px solid blue"
      // position:"fixed",
      // top:0,
      // left:0,
      // background:"white",
      // zIndex:100,
      // border:"1px solid red",
      // width:240
    },
  },
  drawerHeaderTypography: {
    sx: {
      fontFamily: "Quicksand",
      fontWeight: "bold",
      color: "white",
      ml: 1,
    },
  },
  // appBar style
  Typo1: {
    sx: {
      fontSize: "1.5rem",
      color: "hsl(0, 0%, 18%)",
      fontWeight: "700",
      m: 0,
      p: 0,
      py: 0.5,
    },
  },
  Typo2: {
    sx: {
      m: 0,
      p: 0,
      fontSize: "0.938rem",
      color: " hsl(240, 1%, 48%)",
      fontWeight: "500",
    },
  },

  searchBar: {
    sx: {
      mt: 1,
      display: "flex",
      flexDirection: "row",
      padding: "1rem 2rem",
      background: "hsl(0, 0%, 100%)",
      borderRadius: " 5px",
      gap: "1rem",
      boxShadow: "0 2px 4px hsl(330, 12%, 97%)",
    },
  },

  searchBarInput: {
    sx: {
      border: "none",
      outline: "none",
      background: "none",
    },
  },
  searchicon: {
    color: " hsl(240, 1%, 48%)",
    //  `&hover:` {

    //   }
  },
  adminDiv: {
    mt: 1,
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    // alignItems: "right",
    justifyContent: "right",
    alignItems: "center",

    width: "100%",
  },
  iconsss: {
    sx: {
      fontSize: "2.5rem",
      background: "hsl(0, 0%, 100%)",
      borderRadius: "5px",
      padding: "5px",
      boxShadow: "0 2px 4px hsl(330, 12%, 97%)",
      color: "hsl(240, 1%, 48%)",
    },
  },
  adminImage: {
    sx: {
      border: "3px solid hsl(0, 0%, 100%)",
      borderRadius: "10px",
      width: "2.5rem",
      height: "2.5rem",
      overflow: "hidden",
      boxShadow: "0 2px 4px hsl(330, 12%, 97%)",
    },
  },
};
