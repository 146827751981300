import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

// Get Age Groups
export const getAgeGroups = createAsyncThunk(actionTypes.GET_AGE_GROUPS, async (params, thunkAPI) => {
 
  const mainUrl = `/ageGroup/all/paginate?&top=${params?.paginationModel?.pageSize}&skip=${params?.paginationModel.page+1}`;
 
  const url = params?.filterData
     ? `${mainUrl}&query=${params?.filterData}`
     : `${mainUrl}`;
  
  try {
    const response = await axiosInstance.get(url);
    console.log('response of ageGroup', response);

    if(params?.filterData){
      return  response.data.filter(
        obj => Object.values(obj).some(
           val => typeof val === "string" && val.includes(params?.filterData)
        )
      );
    }
 
    return response.data;

    // return response.data.slice(params?.page + 1, 
    //   params?.rowsPerPage);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Add Age Group
export const addAgeGroups = createAsyncThunk(
  actionTypes.ADD_AGE_GROUPS,

  async (params, thunkAPI) => {
  
    // console.log('paramshghgf', params);
    try {
      const response = await axiosInstance.post(`/ageGroup/create`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response of add Category', response);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Update Age Group
export const updateAgeGroup = createAsyncThunk(actionTypes.UPDATE_AGE_GROUPS, async (params, thunkAPI) => {
  console.log('params in update', params);
  try {
    const response = await axiosInstance.put(`/ageGroup/update/${params.id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response Category update', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Delete Age Group
export const deleteAgeGroup = createAsyncThunk(actionTypes.DELETE_AGE_GROUPS, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.delete(`/ageGroup/delete/${params.id}`);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get Age Group Image
export const getAgeGroupImage = createAsyncThunk(actionTypes.DOWNLOAD_AGE_GROUP_IMAGE, async (params, thunkAPI) => {
  try {
    const imgUrl = await `${axiosInstance.getUri()}ageGroup/download/${params}`;
    return imgUrl;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});