import { configureStore } from '@reduxjs/toolkit';
import authentication from './reducer/users/authentication';
import dashboard from './reducer/dashboard/dashboard';
import userInfo from './reducer/usersInfo/userInfo';
import category from './reducer/Category/Category';
import ageGroups from './reducer/AgeGroups/AgeGroups';
import subCategory from './reducer/SubCategory/subCategoryReducer';
import contents from './reducer/Content/contentReducer';
// store configuration

export const Store = configureStore({
  reducer: {
    authentication,
    dashboard,
    userInfo,
    category,
    ageGroups,
    subCategory,
    contents
  },
  // middleware for async api calls

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


