import { createSlice } from '@reduxjs/toolkit';
import { loginAdmin, logoutAdmin } from '../../actions/userActions';

const initialState = {
  isLogged: false,
  user: null,
  isLoggedOut: false,
  isLoadingRequest: false,
  role: '',
};

const Authentication = createSlice({
  name: 'authentication',
  initialState,

  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginAdmin.pending, (state) => {
      state.isLoadingRequest = true;
    });

    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      
      console.log('action.payload', action.payload);
      let isLoggedValue;

      if (state.isLogged === true) {
        isLoggedValue = 1;
      } else if (state.isLogged === 1) {
        isLoggedValue = true;
      } else {
        isLoggedValue = !state.isLogged;
      }
      console.log('isLoggedValue', isLoggedValue);
      state.user = action.payload;
      state.isLoadingRequest = false;
      state.isLogged = isLoggedValue;
     // TODO:: Decode token and add role to state
     // state.role = action.payload?.data?.data[0]?.role;
      localStorage.setItem('isLogged', state.isLogged);
      console.log('stateUser', state.user);
      localStorage.setItem('role', state.role);
       // TODO:: Decode token and add user to state
   // localStorage.setItem('user', JSON.stringify(state?.user?.data.data[0]));
   // localStorage.setItem('userUuid', JSON.stringify(state?.user?.data.data[0]?.uuid));
      // localStorage.setItem('user', JSON.parse(state.user));
    });
    builder.addCase(loginAdmin.rejected, (state, action) => {
      console.log('action.payload in login ', action.payload);
      state.isLoadingRequest = false;
      state.user = null;
      state.isLogged = null;
    });

    // ------------Logout reducer------------------
    builder.addCase(logoutAdmin.pending, (state) => {
      state.isLoadingRequest = true;
    });
    builder.addCase(logoutAdmin.fulfilled, (state, action) => {
      state.user = null;
      state.isLoadingRequest = false;

      state.isLoggedOut = !state.isLoggedOut;
    });
    builder.addCase(logoutAdmin.rejected, (state) => {
      state.isLoadingRequest = false;
    });
  },
});

export default Authentication.reducer;
