import React from 'react';
// import mapByAgeTopbar from './mapByAgetopbar';

const MapByAgeGroup = () => {
  return (
    <div>
      {/* <mapByAgeTopbar/> */}
      Map By age group here..
      
    </div>
  );
};

export default MapByAgeGroup;
