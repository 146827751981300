// import { SetNewAlert } from '../../actions/AlertActions';

const HandleExceptionWithSecureCatch = (exception) => {
  return (dispatch) => {
    const message = exception?.data?.message;
    const statusCode = exception?.data?.status;

    if (statusCode === 408 && window.location.pathname !== '/login') {
      // dispatch(
      //   // SetNewAlert({
      //   //   msg: 'Session Expired',
      //   //   alertType: 'danger',
      //   // })
      // );
    } else {
      // dispatch(
      //   SetNewAlert({
      //     msg: message,
      //     alertType: `${exception?.data?.status === 200 ? 'success' : 'danger'}`,
      //   })
      // );
    }
  };
};

export { HandleExceptionWithSecureCatch };
